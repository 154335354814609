@import '@fortawesome/fontawesome-free/css/all.min.css';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

//@import "~bootstrap/scss/bootstrap.scss";
@import "~bootstrap/scss/mixins/banner";

@include bsBanner("");


// scss-docs-start import-stack
// Configuration
@import "~bootstrap/scss/functions";
@import "./grande/scss/variables";
@import "~bootstrap/scss/variables-dark";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";

// Layout & components
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";

$lead-font-size: 1.5rem;
$h4-font-size: 2.5rem;

@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/accordion";
//@import "~bootstrap/scss/breadcrumb";
//@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
//@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/spinners";
//@import "~bootstrap/scss/offcanvas";
//@import "~bootstrap/scss/placeholders";

// Helpers
@import "~bootstrap/scss/helpers";

// Utilities
@import "~bootstrap/scss/utilities/api";
// scss-docs-end import-stack


@keyframes blink {
  50% {
    border-color: transparent;
  }
}

.blink-success {
  animation: blink 1s ease-in-out 3; /* Blinks 3 times */
}

html {
  font-size: 14px;
}

.nav-link {
  padding: 1rem 2rem !important;
}

a:hover {
  --bs-link-color-rgb: $black;
}

.form-check-input {
  border: solid 2px $primary;
}

// FONTS -- START
.montserrat-product-title {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  font-size: 1.15rem;
}

.montserrat-product-homepagebannertitle {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  font-size: 2.5rem;
}

.montserrat-product-light {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;
  font-size: 2.5rem;
}

.montserrat-product-light-h2 {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;
  font-size: 1.5rem;
}

.montserrat-product-images {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  font-size: .95rem;
}

.montserrat-product-menu {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  font-size: 1.2rem;
}

// FONTS -- END

.slidedownmenu .submenutitl .gs-link {
  color: $black;
  //  font-weight: bold;

  &:hover {
    color: $primary !important;
  }
}

.graytowhite {
  height: 100%;
  background: linear-gradient(to bottom, rgba(154, 154, 154, .1) .1%, white 1.5%);
  padding-top: 50px;
}

.productcard {
  border: none;
  box-shadow: none !important;
}

.producttextholder {
  min-height: 187px;

  p {
    font-size: 16px;
  }
}

.productcardimage {
  position: relative;
  border: solid 1px gray;
  aspect-ratio: 1 / 1;
  width: 100%;
  display: flex;
  background-color: #ffffff;
}

.prosicons {
  display: block;
  float: right;
  width: 180px;
  margin-top: 10px;
}

.graytowhite {

  .triangle-label span {
    position: absolute;
    top: 10px; /* Adjust based on triangle size */
    right: 25px; /* Move it into the triangle */
    color: $black;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    white-space: nowrap;

    span {
      position: relative;
      top: 0;
      right: 0;
      font-weight: normal;
      text-transform: none;
    }
  }

  .origprodimage {
    .triangle-label span {
      position: absolute;
      top: 0px; /* Adjust based on triangle size */
      right: 100px; /* Move it into the triangle */
      color: $black;
      font-size: 16px;
      font-weight: bold;
      text-transform: uppercase;
      white-space: nowrap;

      span {
        position: relative;
        top: 0;
        right: 0;
        font-weight: normal;
        text-transform: none;
      }
    }
  }
}

.triangle-label span {
  position: absolute;
  top: 10px; /* Adjust based on triangle size */
  right: 10px; /* Move it into the triangle */
  color: $black;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  white-space: nowrap;

  span {
    position: relative;
    top: 0;
    right: 0;
    font-weight: normal;
    text-transform: none;
  }
}

.productcardshadow:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  transition: box-shadow 0.3s ease-in-out;
  //transition: all 0.3s ease-in-out;
}

.activeattributeicon {
  filter: invert(100%) sepia(100) saturate(0) hue-rotate(0deg) brightness(0%) contrast(0%);

  &-link {
    color: $secondary;
    text-decoration: none;
  }

  .prodtab p {
    width: 100% !important;
  }
}

.leftarrow {
  left: -150px;
  color: $secondary !important;

  &:hover {
    left: -150px;
    color: $primary !important;
  }

  &:active {
    left: -150px;
    color: $primary !important;
  }
}

.rightarrow {
  right: -150px;
  color: $secondary;

  &:hover {
    color: $primary;
  }

  &:active {
    color: $primary !important;
  }
}

.carousel-control-prev {
  color: $secondary !important;

  &:hover {
    color: $primary !important;
  }

  &:active {
    color: $primary !important;
  }
}

.carousel-control-next {
  color: $secondary !important;

  &:hover {
    color: $primary !important;
  }

  &:active {
    color: $primary !important;
  }
}

.producttextholder {
  h4 {
    a {
      color: $black;

      &.montserrat-product-title:hover {
        color: $primary !important;
      }
    }
  }
}

/* FOOTER */
.bg-blue-light {
  background-color: rgba(113, 195, 235, 0.2);
}

.social .list-group-item {
  border: none !important;
}

h4 {
  font-size: 2.5rem;
}

.nav-tabs .nav-link {
  font-size: 1.8rem;
}

.icon-text {
  font-size: 1.8rem;
  text-decoration: none;
  color: $black !important;
  hyphens: auto;
  overflow-wrap: break-word;

  h2 {
    font-weight: bold;
  }


  &:hover {
    h2 {
      color: $primary !important;
    }

  }
}

#newscarousel {
  h4 a {
    color: $black;
    font-weight: bold;

    &:hover {
      color: $primary;
    }
  }
}

.newsitem {
  h4 {
    font-size: 1.8rem;
  }

  p {
    font-size: 1.3rem;
  }
}

.btn {
  border-radius: 5px;
  font-size: 1.36rem !important;
  padding: 1rem 2rem;
}

.btn-primary {
  color: white !important;
}

.numbers {
  h2 {
    font-size: 2.5rem;
    color: $primary !important;
  }

  p {
    font-size: 1.3rem;
  }
}

.lead {
  font-size: 1.5rem !important;
}

.footerclass {
  font-size: 1.2rem !important;

  .footernav .nav-link {
    padding: 0 !important;
  }
}

.prodtab {
  font-size: 1.3rem !important;
}

.introtext, .extraabout {
  font-size: 1.3rem !important;
}


.btn-outline-primary:hover {
  color: #fff !important;
}

.slidedownmenu {
  position: absolute;
  top: 156px;
  border-top: solid 2px $primary;
  background-color: #fff !important;
  width: 100%;
  z-index: 999;
  left: 0;

  .submenutitl {
    font-size: 1.5rem !important;

    a {
      text-decoration: none;

      &:hover {
        color: $secondary !important;
      }
    }
  }

  .navlink {
    font-size: 1.3rem;
    color: #000 !important;
    text-decoration: none;
    display: flex;
    margin-bottom: 10px;

    &:hover {
      color: $primary !important;
    }
  }
}

.border-right .mt-5 h5 {
  color: $primary !important;
}

.sidemenulink {
  text-decoration: none !important;
  font-size: 1.3rem !important;
  margin-bottom: 15px;
  color: $black !important;

  &:hover {
    color: $primary !important;
  }
}

.dropdown-toggle::after {
  content: none !important;
  border: none !important;
}

.grayimg {
  filter: gray(); /* IE6-9 */
  -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
  filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
  height: 45px !important;
}

.montserrat-product-title:hover {
  color: #000;
}

.nav-tabs .nav-link:hover {
  color: $primary;
  text-decoration: underline;
}

.productcardshadow .btn {
  font-weight: normal !important;
}

.size2 {
  font-size: 2rem;
}

.iconboxholder {
  .mainicon {
    display: block;
  }

  .hovericon {
    display: none;
  }

  &:hover {
    .mainicon {
      display: none;
    }

    .hovericon {
      display: block;
    }

    .zoomholder {

      a {
        color: $secondary !important;

        &:hover {
          color: $primary !important;
          font-weight: bold;
        }
      }
    }
  }
}

.nav-tabs .nav-link {
  color: $black !important;
  text-decoration: none !important;

  &:hover {
    color: $primary !important;
    text-decoration: none !important;
  }
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: $primary !important;
}


.px200 {
  padding-left: 200px !important;
  padding-right: 200px !important;
}

.addtobasketicon {
  cursor: pointer;
  border: solid 1px $primary;
  border-radius: 100px;
  padding: 5px;

  &:hover {
    background-color: $primary;

    img {
      filter: brightness(0) invert(1);
    }

    &:after {
      border: solid 1px $primary;
      padding: 10px;
    }
  }


}


.socialicons {
  display: inline-block;
  padding: 0 10px;

  svg {
    color: #C4C4C4;
  }

  &:hover {
    svg {
      color: $primary;
    }
  }
}

.footerlogo {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  bottom: 0;
  width: 100%;
}

.grandelogo {
  width: 350px;
}

.productvarinfo {
  font-size: 16px;

  .pprice {
    font-size: 25px;
  }
}

.inputheight {
  height: 60px;
}

.pwimage {
  width: 25%;
  margin-right: 10px;
}

.footertextcenter {
  text-align: left;
}

.col-black-outline {
  border: solid 1px $black !important;
  border-radius: 15px !important;
  padding: 10px 20px !important;
}

.slider-p {
  font-size: 1.3rem;
  width: 50%;
}

.btn-slidercustom {
  border: solid 1px $black;
  color: $black;

  &:hover {
    border: solid 1px $black;
    background-color: $black;
    color: $white;
  }
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=UTF8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%236c757d' viewBox='0 0 16 16'%3E%3Cpath d='M11.354 1.354a.5.5 0 0 1 0 .707L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=UTF8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%236c757d' viewBox='0 0 16 16'%3E%3Cpath d='M4.646 1.354a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 1 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.707z'/%3E%3C/svg%3E");
}

.activesite {
  font-weight: bold !important;
}

.border-right .mt-5 h5.inactivesite {
  color: $black !important;
  text-decoration: none !important;
}

.inactivesite {
  color: $black !important;
  text-decoration: none !important;
}

.homepagetitle {
  font-size: 2.5rem;
}

.grupleft {
  border-radius: 0 10px 10px 0 !important;
}

.point {
  cursor: pointer !important;

  &:hover {
    color: $primary !important;
    filter: invert(42%) sepia(94%) saturate(594%) hue-rotate(152deg) brightness(99%) contrast(104%);
  }
}

.usermenu {
  a {
    color: $black;
    font-size: 1.3rem;

    &.active {
      color: $primary !important;
      text-decoration: underline;
      font-weight: bold;
    }

    &:hover {
      color: $primary !important;
      text-decoration: underline;
      font-weight: bold;
    }
  }
}

.font-13 {
  font-size: 1.3rem;
}

.table > :not(caption) > * > * {
  font-size: 14px;
}

#productCarousel {
  height: 850px;

  .carousel-inner {
    height: 850px;

    .productcard {
      height: 850px;
      padding: 20px;
    }
  }
}

.closepromobox {
  display: none;
}

@include media-breakpoint-down(md) {
  #productCarousel {
    height: 850px;

    .carousel-inner {
      height: 850px;

      .productcard {
        height: 850px;
      }
    }
  }
  .prosicons {
    display: block;
    float: right;
    width: 135px;
    margin-top: 5px;
  }

  .top90 {
    position: relative;
    top: 90px;
    padding-bottom: 90px;
  }

  .top90message {
    position: relative;
    top: 90px;
  }

  .homepagetitle {
    font-size: 1.8rem;
  }
  .h4homepage {
    font-size: 1.7rem;
  }

  .montserrat-product-light p {
    font-size: 26px;
  }

  .menuicon {
    float: right;
    margin-top: 5px;
  }
  .mr10 {
    margin-right: 10px !important;
  }
  .footertextcenter {
    text-align: center;
  }
  header {
    position: fixed;
    z-index: 2000;
    background: white;
    width: 100vw;
    border-bottom: solid 1px $primary;
    -webkit-box-shadow: 0 10px 6px -6px #777;
    -moz-box-shadow: 0 10px 6px -6px #777;
    box-shadow: 0 10px 6px -6px #777;
    top: 0;

    & + div {
      padding-top: 68px;
    }

    .grandelogo {
      width: 157px;
    }


    #navbarSupportedContent {
      position: absolute;
      background-color: white;
      z-index: 1;
      width: 100vw;
      left: -11px;
      top: 66px;
      border-top: solid 1px $primary;
      border-bottom: solid 1px $primary;
      -webkit-box-shadow: 0 10px 6px -6px #777;
      -moz-box-shadow: 0 10px 6px -6px #777;
      box-shadow: 0 10px 6px -6px #777;

      ul {
        display: block;
        width: 100%;

        li {
          display: inline-block;
          width: 100%;
        }
      }
    }

    .slidedownmenu {
      top: 0;
      border-bottom: solid 1px $primary;

      .mmholder {
        padding: 75px 0 0 20px !important;
        margin: 0 0 0 0 !important;
        height: 100vh;
        overflow: scroll;

        div {
          margin-bottom: 20px !important;
        }

        h4.submenutitl.mb-5 {
          margin-bottom: 20px !important;;
        }
      }

    }

    .m-back {
      background-color: $primary;
      color: #FFFFFF;
      padding: 20px 20px 20px 10px !important;
      top: 0;
      font-size: 20px;
      z-index: 1;
    }

  }

  .nav-tabs .nav-link {
    font-size: 1.2rem !important;
  }

  .m-padding-bottom-5 {
    padding-bottom: 3rem;
  }

  .pwimage {
    width: 17%;
    margin-right: 0;
  }
  .accordion-item > .accordion-header .accordion-button {
    font-size: 20px;
    display: flex;
  }

  .matitle {
    font-size: 15px;
  }

  .homepageimage {
    height: 450px;
    object-fit: cover;
    object-position: 76% 0;
  }

  .carousel-item {
    height: 505px; /* Let the image determine the height */
  }

  .carousel-caption {
    position: relative;
    padding: 10px 20px;
    left: 0;
    top: 10px;
  }

  .carousel-caption h1 {
    font-size: 1.5rem;
    margin-top: 10px;
    width: 100%;
    text-align: center;
  }

  .carousel-caption h2 {
    font-size: 1.2rem;
    margin-top: 10px;
    width: 100%;
    text-align: center;
  }

  .carousel-caption p.slider-p {
    font-size: 1rem;
    margin-top: 10px;
    width: 100%;
    text-align: center;
  }

  .carousel-control-prev, .carousel-control-next {
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
  }

  .carousel-control-prev-icon, .carousel-control-next-icon {
    width: 100%;
    height: 100%;
  }

  #productCarousel {
    &.slideproduct .carousel-inner .productcard {
      width: 250px !important;
      margin: auto;
    }

    .leftarrow {
      left: 0;
      top: 300px;
    }

    .rightarrow {
      right: 0;
      top: 300px;
    }
  }

  .leftarrow {
    left: 0;
    top: 300px;
  }
  .rightarrow {
    right: 0;
    top: 300px;
  }
  .grandepromobox {
    position: fixed;
    bottom: 0;
    z-index: 1000;

    .closepromobox {
      display: block;
      margin-top:10px;
    }
  }
}


/* END FOOTER */

.message .text-success {
  font-size: 20px !important;
  margin-top: 20px !important;
}

.fixed-bottom .nav {
  background-color: #fff;
  padding: 10px;
  border-top: 1px solid #ddd;
}

.fixed-bottom button {
  width: 100% !important; /* Ensure the button takes up full width at the bottom */
  border-radius: 0 !important; /* Remove rounded corners */
}

@keyframes blink1 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.blink-border {
  border: 2px solid $primary !important;
  animation: blink1 0.5s step-start 2; /* Blink 4 times within 2 seconds */
}

.dropdown-menu[data-bs-popper] {
  left: -115px;
}

.form-label {
  margin-top: 10px;
}

.addtoadressbook {
  border-radius: 10px !important;
  padding: 5px 10px !important;
  font-size: 15px !important;
}

@keyframes slideUp {
  from {
    max-height: 200px;
    opacity: 1;
  }
  to {
    max-height: 0;
    opacity: 0;
  }
}

.slide-up {
  animation: slideUp 1s forwards;
  overflow: hidden;
}

@import "./grande/scss/ipadpro";