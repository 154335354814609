@media (min-width: 1001px) and (max-width: 1201px) {
  .carousel-item {
    height: 710px;
  }
  .carousel-control-prev, .carousel-control-next{
    top:70%
  }
}

@media (min-width: 801px) and (max-width: 1000px) {
  .carousel-item {
    height: 630px;
  }
  .carousel-control-prev, .carousel-control-next{
    top:70%
  }
}

@media (min-width: 601px) and (max-width: 800px) {
  .carousel-item {
    height: 570px;
  }
  .carousel-control-prev, .carousel-control-next{
    top:70%
  }
}